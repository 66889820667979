import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import colors from 'vuetify/lib/util/colors'

const theme1 = {
  primarydark: '#4f6367',
  primary: '#7a9e9f',
  primarylight: '#b8d8d8',
  onprimary: colors.shades.white,
  onprimarylight: '#3b494c',
  secondarydark: '#c43c00',
  secondary: '#fe5f55',
  secondarylight: '#ff9e40',
  onsecondary: colors.shades.black,
  error: colors.red.accent4,
  info: colors.lightGreen.accent4,
  success: colors.lightGreen.accent4,
  warning: colors.deepOrange.accent1,
}

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: theme1
    }
  }
})

Vue.use(Vuetify);

export default vuetify
