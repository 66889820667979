<template>
  <div>
    <tab-content>
      <v-row>
        <v-col cols="12">
          <v-list three-line :key="refreshProfilesKey">
            <template v-for="(pid, n) in profilesList">
              <v-divider :key="`D${n}`"></v-divider>
              <v-list-item :key="`L${n}`">
                <v-list-item-avatar size="80">
                  <v-img :src="avatarUrl(pid)" alt="Photo"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ profilesData[pid].name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ profilePhone(pid) }}, {{ profileEmail(pid) }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ profilesData[pid].introduction }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <div class="font-italic pr-2">
                      <template v-for="s in profilesData[pid].skills">
                        {{ $store.getters.skillName(s) }},
                      </template>
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-divider></v-divider>
          </v-list>
        </v-col>
      </v-row>
    </tab-content>
  </div>
</template>

<script>
import TabContent from '../components/TabContent.vue'
import { eventBus } from '@/main'
import { fbfs } from '@/plugins/firebase'
import { collection, getDocs } from 'firebase/firestore'

export default {
  name: 'ProfilesView',
  components: { TabContent },
  data() {
    return {
      profilesList: [],
      profilesData: {},
      refreshProfilesKey: 1
    }
  },
  computed: {
  },
  methods: {
    avatarUrl(profileId) {
      try {
        return this.$store.getters.photoUrl(
          profileId,
          this.profilesData[profileId].photos.avatar
        )
      } catch (e) {
        return null
      }
    },
    profilePhone(profileId) {
      return this.profilesData[profileId].phone || 'no phone'
    },
    profileEmail(profileId) {
      return this.profilesData[profileId].email || 'no email'
    }
  },
  async created() {
    eventBus.$emit('appTabsSet', null)
    eventBus.$emit('appAlterAppBar', {
      alter: false,
      title: 'Profiles',
      buttons: [ { show: false }, { show: false }, { show: false } ],
      loading: true
    })
    try {
      let snapshot
      snapshot = await getDocs(collection(fbfs, 'profiles'))
      snapshot.forEach((doc) => {
        this.profilesList.push(doc.id)
        this.profilesData[doc.id] = doc.data()
      })
      snapshot = await getDocs(collection(fbfs, 'accounts'))
      snapshot.forEach((doc) => {
        if (this.profilesList.includes(doc.id)) {
          Object.assign(this.profilesData[doc.id], { ... doc.data() })
        } else {
          this.profilesList.push(doc.id)
          this.profilesData[doc.id] = doc.data()
        }
      })
    } catch (e) {
      eventBus.$emit('appWarningSet', { message: 'Error: ' + e })
    }
    this.refreshProfilesKey += 1
    eventBus.$emit('appAlterAppBar', { loading: false })
  },
  beforeDestroy() {
  }
}
</script>
