// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBc874pIWnwDDjz_UMunJ7hV18-WjriKjk",
  authDomain: "mam-fe-dev.firebaseapp.com",
  projectId: "mam-fe-dev",
  storageBucket: "mam-fe-dev.appspot.com",
  messagingSenderId: "225448884056",
  appId: "1:225448884056:web:57bd56353e1f577cbbd72d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const fbfs = getFirestore(app);
export const fbauth = getAuth(app);
export const fbfuncs = getFunctions(app, 'europe-west2');
export const fbstorage = getStorage(app);
