<template>
  <tab-content>
    <v-row>
      <!-- Notes from database -->
      <v-col v-for="(note, n) in notes" :key="n" cols="12" sm="8" offset-sm="2">
        <action-card v-if="showPlainNote(note)"
          :type="note.type"
          :title="note.title"
          closeable @close="onCloseNote(note.id)"
        >
          <div v-if="note.text">{{ note.text }}</div>
          <div v-if="note.html" v-html="note.html"></div>
        </action-card>
      </v-col>
    </v-row>
  </tab-content>
</template>

<script>
import TabContent from '@/components/TabContent.vue'
import ActionCard from '@/components/ActionCard.vue'
import { eventBus } from '@/main'
import { fbfs } from '@/plugins/firebase'
import { collection, doc, query, where, orderBy, getDocs, updateDoc } from 'firebase/firestore'

export default {
  name: 'DashboardView',
  components: { TabContent, ActionCard },
  data() {
    return {
      notes: []
    }
  },
  computed: {
  },
  methods: {
    async onCloseNote(id) {
      this.notes.forEach((note) => {
        if (note.id === id) {
          note.show = false
        }
      })
      await updateDoc(
        doc(fbfs, 'dashboard', id),
        { show: false }
      )
    },
    showPlainNote(note) {
      return (note.type === 'note' || note.type === 'info' || note.type === 'warning'|| note.type === 'help') && note.show
    },
  },
  async created() {
    eventBus.$emit('appTabsSet', null)
    eventBus.$emit('appAlterAppBar', {
      alter: false,
      title: 'Dashboard',
      buttons: [ { show: false }, { show: false }, { show: false } ],
      loading: true
    })

    try {
      const snapshot = await getDocs(query(
        collection(fbfs, 'dashboard'),
        where('user', '==', this.$store.state.user.uid),
        where('show', '==', true),
        orderBy('order')
      ))
      snapshot.forEach((doc) => {
        this.notes.push({ id: doc.id, ...doc.data() })
      })
    } catch (e) {
      console.log('Dashboard', e)
      eventBus.$emit('appWarningSet', { message: 'Error: ' + e })
    }

    eventBus.$emit('appAlterAppBar', { loading: false })
  }
}
</script>
